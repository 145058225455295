<template>
  <div id="manage-employees">
    <h2 class="content-title" >Gestionar empleados</h2>
    <div class="content-container" :class="loadingContent ? '' : 'visible'">
      <q-card v-if="!displayForm" class="content-card card">
        <p class="content-card__title">Estadísticas</p>
        <div class="employee-stats row">
          <div class="col-sm-4">
            <q-card>
              <q-card-section>
                <span class="employee-stats__title">Número de empleados</span>
                <div class="employee-chart">
                  <PieChart :chart-data="numEmployees" />
                </div>
              </q-card-section>
            </q-card>
          </div>
          <div class="col-sm-4">
            <q-card>
              <q-card-section>
                <span class="employee-stats__title">Distribución salario bruto (€)</span>
                <div class="emplopyee-chart">
                  <PieChart :chart-data="salaryDistribution" />
                </div>
              </q-card-section>
            </q-card>
          </div>
          <div class="col-sm-4">
            <q-card>
              <q-card-section>
                <span class="employee-stats__title">Empleados activos</span>
                <span class="employee-stats__count">{{ totalActive }}</span>
              </q-card-section>
            </q-card>
            <q-card>
              <q-card-section>
                <span class="employee-stats__title">Empleados de baja</span>
                <span class="employee-stats__count">{{ totalInactive }}</span>
              </q-card-section>
            </q-card>
            <q-card>
              <q-card-section>
                <span class="employee-stats__title">Total salarios brutos</span>
                <span class="employee-stats__count">{{ totalGross }}</span>
              </q-card-section>
            </q-card>
          </div>
        </div>
      </q-card>
      <q-card v-if="!displayForm" class="content-card">
        <p class="content-card__title">Listado de empleados</p>
        <div class="employees-container">
          <q-table
            no-data-label="No hay ningún empleado para mostrar"
            :data="employeesTD"
            :columns="employeesTC"
            :filter="filter"
            :visible-columns="employeesVC"
            row-key="num_ss"
            selection="multiple"
            :selected.sync="selectedEmployees"
          >

            <template v-slot:top>
              <q-btn 
                v-if="selectedEmployees.length == 0" 
                class="table-btn" 
                color="primary" 
                label="Añadir" 
                @click="addEmployee" 
              />
              <q-btn 
                v-if="selectedEmployees.length == 1" 
                class="table-btn" 
                color="primary" 
                label="Modificar" 
                @click="updateEmployee" 
              />
              <q-btn 
                v-if="selectedEmployees.length > 0" 
                class="table-btn" 
                color="red"
                :loading="btnLoadingChangeStatus" 
                :label="activeEmployees ? 'Dar de baja' : 'Dar de alta'" 
                @click="changeStatusEmployee" 
              />
              <q-space />
              <q-toggle
                class="form-filter"
                left-label
                :label="activeEmployees ? 'Empleados activos' : 'Empleados de baja'"
                v-model="activeEmployees"
                @input="emptySelection"
              />
              <q-select 
                  class="form-filter col-sm-2" 
                  v-model="selectedSchool"
                  :options="allSchoolNames"
                  emit-value 
                  map-options 
                  label="Escuela"
              />
              <q-input borderless dense debounce="300" v-model="filter" placeholder="Buscar">
                <q-icon slot="append" name="search" />
              </q-input>
            </template>

            <template v-slot:body="props">
              <q-tr :props="props" :class="(props.row.status == 'historic') ? 'table__disabled-row' : ''">
                <q-td>
                  <q-checkbox v-model="props.selected"/>
                </q-td>
                <q-td
                  v-for="col in props.cols"
                  :key="col.name"
                  :props="props"
                >
                  {{ col.value }}
                </q-td>
              </q-tr>
            </template>

          </q-table>
        </div>
      </q-card>
      <q-card v-if="displayForm" class="content-card card">
        <p class="content-card__title">{{ formAction == 'update' ? 'Modificar empleado' : 'Añadir empleado' }}</p>
        <div class="employee-form-container">
          <q-form @submit="onSendForm" class="employee-form">
            <div class="text-subtitle1">Datos personales</div>
            <div class="row form-row items-start">
              <q-input
                :readonly="formAction == 'update'"
                class="employee-form__input col-sm-4 form-column"
                v-model="employeeForm.dni"
                label="DNI"
                placeholder="DNI del empleado"
                lazy-rules
                :rules="[
                  val => val && isMandatory(val) || translate('error', 'field_empty')
                ]"
              />
              <q-input
                class="employee-form__input col-sm-4 form-column"
                v-model="employeeForm.firstname"
                label="Nombre"
                placeholder="Nombre"
                lazy-rules
                :rules="[
                  val => val && isMandatory(val) || translate('error', 'field_empty')
                ]"
              />
              <q-input
                class="employee-form__input col-sm-4 form-column"
                v-model.trim="employeeForm.lastname"
                label="Apellidos"
                placeholder="Apellidos"
                lazy-rules
                :rules="[
                  val => val && isMandatory(val) || translate('error', 'field_empty')
                ]"
              />
              <q-input
                class="employee-form__input col-sm-4 form-column"
                v-model.trim="employeeForm.email"
                label="Correo electrónico"
                placeholder="Correo electrónico"
                lazy-rules
                :rules="[
                  val => val && isMandatory(val) || translate('error', 'field_empty'),
                  val => val && validEmail(val) || translate('error', 'email_format')
                ]"
              />
              <q-input
                class="employee-form__input col-sm-4 form-column"
                v-model="employeeForm.phone_number"
                label="Número de teléfono"
                placeholder="Número de teléfono"
                lazy-rules
                :rules="[
                  val => !val || validPhone(val) || translate('error', 'phone_format')
                ]"
              />
              <q-input
                class="employee-form__input col-sm-4 form-column"
                v-model="employeeForm.birthday"
                label="Fecha de nacimiento"
                placeholder="dd/mm/aaaa"
                lazy-rules
                :rules="[
                  val => !val || validDate(val) || translate('error', 'date_format')
                ]"
              />
              <q-input
                class="employee-form__input col-sm-4 form-column"
                v-model="employeeForm.address"
                label="Dirección"
                placeholder="Dirección del empleado"
              />
              <q-input
                class="employee-form__input col-sm-3 form-column"
                v-model="employeeForm.city"
                label="Ciudad"
                placeholder="Ciudad"
              />
              <q-input
                class="employee-form__input col-sm-3 form-column"
                v-model="employeeForm.province"
                label="Provincia"
                placeholder="Provincia"
              />
              <q-input
                class="employee-form__input col-sm-2 form-column"
                v-model="employeeForm.zip"
                label="Código postal"
                placeholder="Código postal"
                lazy-rules
                :rules="[
                  val => !val || validZip(val) || translate('error', 'zip_format')
                ]"
              />
            </div>
            <div class="text-subtitle1">Datos de empresa</div>
            <div class="row form-row items-start">
              <q-input
                :readonly="formAction == 'update'"
                class="employee-form__input col-sm-3 form-column"
                v-model.trim="employeeForm.num_ss"
                label="Nº Seguridad Social"
                placeholder="Nº S.S. del empleado"
                lazy-rules
                :rules="[
                  val => val && isMandatory(val) || translate('error', 'field_empty'),
                  val => val && isNumber(val) || translate('error', 'num_ss_format')
                ]"
              />
              <q-select 
                class="employee-form__input col-sm-3 form-column" 
                v-model="employeeForm.school_id" 
                :options="schoolNames" 
                emit-value 
                map-options 
                label="Escuela principal"
                lazy-rules
                :rules="[
                  val => val && isMandatory(val) || translate('error', 'field_empty')
                ]"
              />
              <q-input
                class="employee-form__input col-sm-3 form-column"
                v-model="employeeForm.gross_salary"
                label="Salario bruto"
                placeholder="Salario bruto"
                lazy-rules
                :rules="[
                  val => !val || isNumber(val) || translate('error', 'salary_format')
                ]"
              />
              <q-input
                class="employee-form__input col-sm-3 form-column"
                v-model="employeeForm.net_salary"
                label="Salario neto"
                placeholder="Salario neto"
                lazy-rules
                :rules="[
                  val => !val || isNumber(val) || translate('error', 'salary_format')
                ]"
              />
              <q-input
                class="employee-form__input col-sm-3 form-column"
                v-model="employeeForm.start_contract"
                label="Fecha de inicio"
                placeholder="Fecha de inicio del contrato"
              />
              <q-input
                class="employee-form__input col-sm-3 form-column"
                v-model="employeeForm.finish_contract"
                label="Fecha de vencimiento"
                placeholder="Fecha de vencimiento del contrato"
              />
              <q-input
                class="employee-form__input col-sm-3 form-column"
                v-model="employeeForm.schedule"
                label="Tipo de jornada"
                placeholder="Tipo de jornada"
              />
              <q-input
                class="employee-form__input col-sm-3 form-column"
                v-model="employeeForm.category"
                label="Categoría"
                placeholder="Categoría del empleado"
              />
              <q-select 
                class="employee-form__input col-sm-3 form-column" 
                v-model="employeeForm.teacher" 
                :options="booleanOptions" 
                emit-value 
                map-options 
                label="Maestra"
              />
              <q-select 
                class="employee-form__input col-sm-3 form-column" 
                v-model="employeeForm.english" 
                :options="booleanOptions" 
                emit-value
                map-options 
                label="Dominio del inglés"
              />
              <q-input
                class="employee-form__input col-sm-6 form-column"
                v-model="employeeForm.remarks"
                label="Observaciones"
                placeholder="Observaciones"
              />
            </div>
            <div class="row items-center justify-center">
              <q-btn 
                class="employee-form__btn"
                type="submit" 
                color="primary"
                :loading="btnLoadingForm"
                :label="formAction == 'update' ? 'Modificar empleado' : 'Añadir empleado'"  
              />
              <q-btn 
                class="employee-form__btn" 
                color="red" 
                @click="onCancelForm"
                label="Cancelar"
              />
            </div>
          </q-form>
        </div>
      </q-card>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import validateForm from '../../mixins/validateForm'
import dictionary from '../../mixins/dictionary'
import PieChart from "../../components/PieChart"

export default {
  mixins: [validateForm, dictionary],

  components: {
		PieChart,
	},

  data() {
    return {
      loadingContent: true,
      btnLoadingChangeStatus: false,
      btnLoadingForm: false,
      filter: null,
      selectedEmployees: [],
      activeEmployees: true,
      selectedSchool: '',
      displayForm: false,
      formAction: '',
      booleanOptions: [
        {
          'label': 'Sí',
          'value': 1
        },
        {
          'label': 'No',
          'value': 0
        }
      ],
      employeesTC: [
        { name: 'num_ss', label: 'Nº S.S.', field: 'num_ss', align: 'center' },
        { name: 'firstname', label: 'Nombre', field: 'firstname', align: 'left', sortable: true },
        { name: 'lastname', label: 'Apellidos', field: 'lastname', align: 'left', sortable: true },
        { name: 'school_name', label: 'Escuela', field: 'school_name', align: 'left', sortable: true },
        { name: 'gross_salary', label: 'Bruto', field: 'gross_salary', align: 'center', sortable: true, type: 'number' },
        { name: 'net_salary', label: 'Neto', field: 'net_salary', align: 'center', sortable: true, type: 'number' },
        { name: 'status', label: 'Estado', field: 'status', align: 'center' },
      ],
      employeesVC: ['firstname', 'lastname', 'school_name', 'gross_salary', 'net_salary'],
      employeeForm: {
        'teacher': 1,
        'english': 0,
      }
    }
  },

  computed: {
    ...mapGetters('auth', [
      'userAuth'
    ]),

    ...mapGetters('employees', [
      'employees'
    ]),

    ...mapGetters('schools', [
      'schools'
    ]),

    employeesTD() {
      let employees = this.employees
      let selectedSchool = this.selectedSchool
      let activeEmployees = this.activeEmployees
      if (selectedSchool > 0) {
        employees = employees.filter(employee => employee.school_id == selectedSchool)
      }
      if (activeEmployees) {
        employees = employees.filter(employee => employee.status == 'active')
      } else {
        employees = employees.filter(employee => employee.status == 'historic')
      }
      return employees
    },

    schoolNames() {
      let schools = this.schools.map(school => {
        return {
          'label': school.name,
          'value': school.id
        }
      })
      return schools
    },

    allSchoolNames() {
      let allLabel = [{
        'label': 'Todas',
        'value': 0
      }]
      let schools = this.schools.map(school => {
        return {
          'label': school.name,
          'value': school.id
        }
      })
      return allLabel.concat(schools)
    },

    numEmployees() {
      let env = this
      let count = this.schools.map(school => {
        let employees = env.employeesTD.filter(employee => employee.school_id == school.id)
        return {
          name: school.name,
          value: employees.length,
        }
      })
      return count
    },

    salaryDistribution() {
      let env = this
      let count = this.schools.map(school => {
        let employees = env.employeesTD.filter(employee => employee.school_id == school.id)
        let salaries = employees.reduce(function(accumulator, currentValue) {
          let gross_salary = Math.round(currentValue.gross_salary)
          if (isNaN(gross_salary)) {
            gross_salary = 0
          }
          return accumulator + gross_salary
        }, 0)
        return {
          name: school.name,
          value: salaries,
        }
      })
      return count
    },

    totalActive() {
      let employees = this.employees.filter(employee => employee.status == 'active')
      return employees.length
    },

    totalInactive() {
      let employees = this.employees.filter(employee => employee.status != 'active')
      return employees.length
    },

    totalGross() {
      let employees = this.employees.filter(employee => employee.status == 'active')
      let salaries = employees.reduce(function(accumulator, currentValue) {
        let gross_salary = Math.round(currentValue.gross_salary)
        if (isNaN(gross_salary)) {
          gross_salary = 0
        }
        return accumulator + gross_salary
      }, 0)

      return salaries.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
    }
  },

  methods: {
    ...mapActions('employees', [
      'getEmployees'
    ]),

    emptySelection() {
      this.selectedEmployees = []
    },

    addEmployee() {
      this.displayForm = true
      this.formAction = 'add'
    },

    updateEmployee() {
      this.displayForm = true
      this.formAction = 'update'
      this.employeeForm = this.selectedEmployees[0]
    },

    async changeStatusEmployee() {
      this.btnLoadingChangeStatus = true
      let form = new FormData()
      let env = this
      form.append('action', 'change_status')
      form.append('token', this.userAuth)
      form.append('employees', JSON.stringify(this.selectedEmployees))
      form.append('status', this.activeEmployees ? 'historic' : 'active')
      await axios
      .post('https://www.api.babyteca.es/api/employees', form)
      .then(function (response) {
        if (response.data.status == 'ok') {
          env.notify('success', env.activeEmployees ? 'ok_fire' : 'ok_hire')
        } else {
          env.notify('error', env.activeEmployees ? 'ko_fire' : 'ko_hire')
        }
        env.btnLoadingChangeStatus = false
        env.updateTable()
        env.emptySelection()
      })
      .catch(function () {
        env.notify('error', env.activeEmployees ? 'ko_fire' : 'ko_hire')
        env.btnLoadingChangeStatus = false
        env.updateTable()
        env.emptySelection()
      })
    },

    async onSendForm() {
      this.btnLoadingForm = true
      let form = new FormData()
      let env = this
      form.append('action', this.formAction)
      form.append('token', this.userAuth)
      form.append('employee', JSON.stringify(this.employeeForm))
      await axios
      .post('https://www.api.babyteca.es/api/employees', form)
      .then(function (response) {
        if (response.data.status == 'ok') {
          env.notify('success', env.formAction == 'update' ? 'ok_update_employee' : 'ok_add_employee')
        } else {
          env.notify('error', env.formAction == 'update' ? 'ko_update_employee' : 'ko_add_employee')
        }
        env.btnLoadingForm = false
        env.updateTable()
        env.emptySelection()
        env.onCancelForm()
      })
      .catch(function () {
        env.notify('error', env.formAction == 'update' ? 'ko_update_employee' : 'ko_add_employee')
        env.btnLoadingForm = false
        env.updateTable()
        env.emptySelection()
        env.onCancelForm()
      })
    },

    onCancelForm() {
      this.employeeForm = {
        'teacher': 1,
        'english': 0,
      }
      this.displayForm = false
    },

    async updateTable() {
      let form = new FormData()
      form.append('token', this.userAuth)
      await this.getEmployees(form)
    }
  },

  async mounted() {
    let env = this
    env.loadingContent = true
    setTimeout(function(){
      env.loadingContent = false
    }, 100)
  }
}
</script>

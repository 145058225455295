<template>
  <div class="chart">
    <pie-chart 
      :data="chartData" 
      :legend="true" 
      :emphasis-label="false" 
      :label="false" 
      :tooltip="true" 
      :option="option" 
    />
  </div>
</template>

<script>
export default {
  name: 'PieBase',
  props: ['chartData'],
  data() {
    return {
      option: {
        tooltip: {
          trigger: 'item',
          formatter: '{c} ({d}%)',
        },
        textStyle: {
          fontFamily: 'Quicksand'
        }
      }
    }
  }
}
</script>
